import angular, { ITimeoutService } from 'angular';
import { SharedAngular } from '../../@types/sharedAngular';
import { IFieldOptionQuery } from '@Shared.Angular/@types/fieldOptions';

angular
  .module('fg')
  .directive('fgFieldSelectList', [
    'fgFieldSelectListLinkFn',
    function (fgFieldSelectListLinkFn) {
      return {
        replace: true,
        templateUrl:
          'angular-form-gen/field-templates/default/selectlist.ng.html',
        scope: true,
        link: fgFieldSelectListLinkFn,
        require: ['^?fgForm']
      };
    }
  ])
  .factory('fgFieldSelectListLinkFn', [
    '$timeout',
    'flowinglyConstants',
    'fieldService',
    function (
      $timeout: ITimeoutService,
      flowinglyConstants: SharedAngular.FlowinglyConstants,
      fieldService: SharedAngular.FieldService
    ) {
      return function ($scope, $element, $attrs, ctrls) {
        const fgForm = ctrls[0];
        const currentStepFields = fgForm?.flowFields || [];
        // There are few flows models which has first option as Please Choose
        // which should be removed from field as below placeholder is being added for same purpose.
        if (
          $scope.field.schema.options &&
          $scope.field.schema.options.length > 0 &&
          $scope.field.schema.options[0].text === 'Please Choose' &&
          $scope.field.schema.options[0].value === ''
        ) {
          $scope.field.schema.options.splice(0, 1);
        }

        //this is placeholder text used in kendo control.
        $scope.options = {
          optionLabel: 'Please Choose...',
          dataBound: disableOuterScrolling,
          filter: $scope.field.schema.searchable === true ? 'contains' : 'none'
        };

        $scope.field.schema.filteredOptions = angular.copy(
          $scope.field.schema.options
        );

        if ($scope.field.schema.searchable === true) {
          $timeout(function () {
            if (
              $scope.field.schema.filteredOptions.length >
                flowinglyConstants.searchableComboPageSize &&
              $scope.form.data[$scope.field.schema.name] &&
              $scope.form.data[$scope.field.schema.name] !== ''
            ) {
              const searchValue = $scope.form.data[$scope.field.schema.name];
              const schemaName = $scope.field.schema.name;

              const field = currentStepFields.find(
                (field) => field.Name === schemaName
              );

              const filteredOptionsList = $scope.field.schema.filteredOptions
                .filter((option) => option.text.indexOf(searchValue) >= 0)
                .slice(0, flowinglyConstants.searchableComboPageSize);

              // If the selected value is not present in the initial dropdown list, appends the text and value from fields object.
              if (
                filteredOptionsList.length <= 0 &&
                field &&
                searchValue === field.Value
              ) {
                $scope.field.schema.filteredOptions = [
                  { value: field.Value, text: field.Text }
                ];
              } else {
                $scope.field.schema.filteredOptions = filteredOptionsList;
              }
            } else {
              $scope.field.schema.filteredOptions =
                $scope.field.schema.filteredOptions.slice(
                  0,
                  flowinglyConstants.searchableComboPageSize
                );
            }

            $scope.options.filtering = function (event) {
              const filter = event.filter;
              event.preventDefault();

              if ($scope.field.schema.dataSource === 'manually') {
                if (!filter.value || filter.value === '') {
                  $scope.field.schema.filteredOptions = angular.copy(
                    $scope.field.schema.options
                  );
                } else {
                  $scope.field.schema.filteredOptions =
                    $scope.field.schema.options.filter(function (val) {
                      return (
                        val.text
                          .toLowerCase()
                          .indexOf(filter.value.toLowerCase()) >= 0
                      );
                    });
                }

                $scope.field.schema.filteredOptions =
                  $scope.field.schema.filteredOptions.slice(
                    0,
                    flowinglyConstants.searchableComboPageSize
                  );

                $timeout(function () {
                  this.setDataSource($scope.field.schema.filteredOptions);
                  this.refresh();
                });
              } else {
                const requestPayload: IFieldOptionQuery = {
                  fieldName: $scope.field.name,
                  dbDataSource: angular.copy($scope.field.schema.dbDataSource),
                  flowModelId: $scope.field.schema.publicForm,
                  searchable: true,
                  searchablePageSize:
                    flowinglyConstants.searchableComboPageSize,
                  searchTerm: filter.value
                };

                if (
                  $scope.field.schema.dbDataSource &&
                  $scope.field.schema.dbDataSource.filters &&
                  $scope.field.schema.dbDataSource.filters.length > 0
                ) {
                  requestPayload.dbDataSource.filters[0].value =
                    $scope.form.data[
                      $scope.field.schema.dbDataSource.filters[0].value
                    ];
                }

                fieldService
                  .getFieldOptions($scope.field.schema.publicForm, [
                    requestPayload
                  ])
                  .then(function (results) {
                    if (results && results.length > 0) {
                      $scope.field.schema.options = results[0].options;
                      $scope.field.schema.filteredOptions = angular.copy(
                        $scope.field.schema.options
                      );
                    }
                  });
              }
            };
          });
        }

        function disableOuterScrolling(e) {
          $('.k-list-scroller').on('mousewheel DOMMouseScroll', function (e) {
            if (e.currentTarget.scrollHeight === e.currentTarget.offsetHeight) {
              return;
            }

            let delta = 0;
            if (e.originalEvent.wheelDelta) {
              // will work in most cases
              delta = e.originalEvent.wheelDelta;
            } else if (e.originalEvent.detail) {
              // fallback for Firefox
              delta = -e.originalEvent.detail;
            }
            const scrollTop = $(e.currentTarget).scrollTop();
            if (
              (delta < 0 &&
                scrollTop ==
                  e.currentTarget.scrollHeight -
                    e.currentTarget.offsetHeight) ||
              (delta > 0 && scrollTop == 0)
            ) {
              e.preventDefault();
            }
          });
        }
      };
    }
  ]);
